/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CashoutState {
    total_account_sales: number;
    total_pairing_bonus: number;
    total_looping_bonus: number;
    total_referral_bonus: number;
    total_account_cashout: number;
    total_product_sales: number;
    total_product_cashout: number;
    cashout_history: any;
    total_binary_fund: number;
}

const initialState: CashoutState = {
    total_account_sales: 0,
    total_pairing_bonus: 0,
    total_looping_bonus: 0,
    total_referral_bonus: 0,
    total_account_cashout: 0,
    total_product_sales: 0,
    total_product_cashout: 0,
    cashout_history: [],
    total_binary_fund: 0,
};

const cashoutSlice = createSlice({
    name: "cashout",
    initialState,
    reducers: {
        saveCashout(
            state,
            action: PayloadAction<{
                data: {
                    total_account_sales: number;
                    total_pairing_bonus: number;
                    total_looping_bonus: number;
                    total_referral_bonus: number;
                    total_account_cashout: number;
                    total_product_sales: number;
                    total_product_cashout: number;
                    cashout_history: any;
                    total_binary_fund: number;
                };
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.total_account_sales = data.total_account_sales;
            state.total_pairing_bonus = data.total_pairing_bonus;
            state.total_looping_bonus = data.total_looping_bonus;
            state.total_referral_bonus = data.total_referral_bonus;
            state.total_account_cashout = data.total_account_cashout;
            state.total_product_sales = data.total_product_sales;
            state.total_product_cashout = data.total_product_cashout;
            state.cashout_history = data.cashout_history;
            state.total_binary_fund = data.total_binary_fund;
        },
    },
});

export const { saveCashout } = cashoutSlice.actions;
export default cashoutSlice.reducer;
