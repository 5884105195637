import React, { Suspense, lazy } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import Preloader from "./components/preloader";
import { useAppSelector } from "./redux/hooks";

const DashboardOne = lazy(() => import("./pages/dashboard-one"));
const Account = lazy(() => import("./pages/account"));
const Product = lazy(() => import("./pages/products"));
const Incentives = lazy(() => import("./pages/incentives"));
const Cashout = lazy(() => import("./pages/cashout"));
const Pincode = lazy(() => import("./pages/pincode"));
const SignIn = lazy(() => import("./pages/signin"));
const Settings = lazy(() => import("./pages/settings"));

const AuthRoute = (props: any) => {
    const { type } = props;
    const { auth } = useAppSelector((state) => state.user);

    if (type === "guest" && auth) return <Redirect to="/" />;
    if (type === "private" && !auth) return <Redirect to="/sign-in" />;

    return <Route {...props} />;
};

const App: React.FC = () => {
    return (
        <>
            <Router>
                <Suspense fallback={<Preloader />}>
                    <Switch>
                        <AuthRoute
                            path="/sign-in"
                            component={SignIn}
                            type="guest"
                        />
                        {/* <AuthRoute
                            path="/account"
                            component={Account}
                            type="private"
                        /> */}
                        {/* <AuthRoute
                            path="/product"
                            component={Product}
                            type="private"
                        />
                        <AuthRoute
                            path="/incentives"
                            component={Incentives}
                            type="private"
                        /> */}
                        <AuthRoute
                            path="/cashout"
                            component={Cashout}
                            type="private"
                        />
                        <AuthRoute
                            path="/pincode"
                            component={Pincode}
                            type="private"
                        />
                        {/* <AuthRoute
                            path="/settings"
                            component={Settings}
                            type="private"
                        /> */}
                        <AuthRoute
                            path="/"
                            component={DashboardOne}
                            type="private"
                        />
                    </Switch>
                </Suspense>
            </Router>

            {/* <SettingsCard /> */}
        </>
    );
};

export default App;
