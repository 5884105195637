/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Accounttate {
    total_account: {
        all: number;
        ez: {
            paid: number;
            free: number;
            cd: number;
        };
        supreme: {
            paid: number;
            free: number;
            cd: number;
        };
    };
    genealogy: any;
    account: {
        details: any;
        earnings: any;
        incentives: any;
    };
    // account_history: any;
}

const initialState: Accounttate = {
    total_account: {
        all: 0,
        ez: {
            paid: 0,
            free: 0,
            cd: 0,
        },
        supreme: {
            paid: 0,
            free: 0,
            cd: 0,
        },
    },
    genealogy: null,
    account: {
        details: null,
        earnings: null,
        incentives: null,
    },
    // account_history: [],
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        saveAccount(
            state,
            action: PayloadAction<{
                data: {
                    total_account: {
                        all: number;
                        ez: {
                            paid: number;
                            free: number;
                            cd: number;
                        };
                        supreme: {
                            paid: number;
                            free: number;
                            cd: number;
                        };
                    };
                    genealogy: any;
                    account: {
                        details: any;
                        earnings: any;
                        incentives: any;
                    };
                    account_history: [];
                };
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.total_account.all = data.total_account.all;
            state.total_account.ez.paid = data.total_account.ez.paid;
            state.total_account.ez.free = data.total_account.ez.free;
            state.total_account.ez.cd = data.total_account.ez.cd;
            state.total_account.supreme.paid = data.total_account.supreme.paid;
            state.total_account.supreme.free = data.total_account.supreme.free;
            state.genealogy = data.genealogy;
            state.account.details = data.account.details;
            state.account.earnings = data.account.earnings;
            state.account.incentives = data.account.incentives;
            // state.account_history = data.account_history;
        },
        setGenealogy(
            state,
            action: PayloadAction<{
                data: any;
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.genealogy = data.genealogy;
            state.account.details = data.account.details;
            state.account.earnings = data.account.earnings;
            state.account.incentives = data.account.incentives;
        },
    },
});

export const { saveAccount, setGenealogy } = accountSlice.actions;
export default accountSlice.reducer;
