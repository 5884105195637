/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PincodeState {
    total_generated_pin: number;
    total_generated_paid: number;
    total_generated_free: number;
    total_generated_cd: number;
    generated_history: {
        total_generated_today: number;
        history: any;
    };
}

const initialState: PincodeState = {
    total_generated_pin: 0,
    total_generated_paid: 0,
    total_generated_free: 0,
    total_generated_cd: 0,
    generated_history: {
        total_generated_today: 0,
        history: [],
    },
};

const pincodeSlice = createSlice({
    name: "pincode",
    initialState,
    reducers: {
        savePincode(
            state,
            action: PayloadAction<{
                data: {
                    total_generated_pin: number;
                    total_generated_paid: number;
                    total_generated_free: number;
                    total_generated_cd: number;
                    generated_history: {
                        total_generated_today: number;
                        history: any;
                    };
                };
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.total_generated_pin = data.total_generated_pin;
            state.total_generated_paid = data.total_generated_paid;
            state.total_generated_free = data.total_generated_free;
            state.total_generated_cd = data.total_generated_cd;
            state.generated_history.total_generated_today =
                data.generated_history.total_generated_today;
            state.generated_history.history = data.generated_history.history;
        },
    },
});

export const { savePincode } = pincodeSlice.actions;
export default pincodeSlice.reducer;
