/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProductState {
    total_generated_pin: number;
    total_product_sales: number;
    total_maintenance: number;
    generated_history: any;
    currentRangeCovered: any;
    total_product_unilevel: number;
    total_product_cashout: 0;
    products: any;
    product_claimed: any;
    product_type: string;
    product_history: any;
}

const initialState: ProductState = {
    total_generated_pin: 0,
    total_product_sales: 0,
    total_maintenance: 0,
    generated_history: [],
    currentRangeCovered: null,
    total_product_unilevel: 0,
    total_product_cashout: 0,
    products: [],
    product_claimed: [],
    product_history: [],
    product_type: "",
};

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        saveProduct(
            state,
            action: PayloadAction<{
                data: {
                    total_generated_pin: number;
                    total_product_sales: number;
                    total_maintenance: number;
                    generated_history: any;
                    currentRangeCovered: any;
                };
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.total_generated_pin = data.total_generated_pin;
            state.total_product_sales = data.total_product_sales;
            state.total_maintenance = data.total_maintenance;
            state.generated_history = data.generated_history;
            state.currentRangeCovered = data.currentRangeCovered;
        },
    },
});

export const { saveProduct } = productSlice.actions;
export default productSlice.reducer;
